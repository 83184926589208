<template>
  <v-row justify="center">
      <v-dialog v-model="shower" scrollable persistent max-width="800px">    
        <v-card>
          <v-card-title>{{$t('keydetail.details')}}<v-spacer></v-spacer>
            <v-icon :color="color.theme" dense dark size="35"
              >mdi-information</v-icon></v-card-title>
          <v-divider></v-divider>
          <!-- {{filedata}} -->
          <v-card-text>
            <v-list two-line subheader>
        <v-subheader>{{$t('keydetail.general')}} <v-divider class="mx-4" ></v-divider></v-subheader>
          <v-list-item-icon style="padding-left: 2%">
            <!-- {{filedata.file_type}} -->
           <v-icon x-large :color="seticon(filedata.file_type)[1]" style="font-weight: 100">{{seticon(filedata.file_type)[0]}}</v-icon>
           </v-list-item-icon>
           <v-list-item>
          <v-list-item-content>
            <v-list-item-title>{{$t('tablefile.filename')}} :</v-list-item-title>
            <v-list-item-subtitle>{{filedata.file_name}}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>  
  
        <v-list-item>
          <v-list-item-content>         
            <v-list-item-title>{{$t('keydetail.filetype')}} :</v-list-item-title>
            <v-list-item-subtitle>{{filedata.type || filedata.data_type }} </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
  
            <v-list-item>
          <v-list-item-content>
            <v-list-item-title>{{$t('tablefile.owner')}} :</v-list-item-title>
            <v-list-item-subtitle>{{$t("default") === "th" ? filedata.file_owner_th : filedata.file_owner_eng}}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>{{$t('tablefile.lastupdatename')}} :</v-list-item-title>
            <v-list-item-subtitle>{{$t("default") === "th" ? filedata.last_update_name_th : filedata.last_update_name_en}}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>{{$t('tablefile.trashdtm')}} :</v-list-item-title>
            <v-list-item-subtitle>{{formatdatetime(filedata.backup_dtm)}}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-content >
            <v-list-item-title>{{$t('tablefile.filesize')}} :</v-list-item-title>
            <v-list-item-subtitle>{{calculatesize(filedata.file_size)}}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-content>         
            <v-list-item-title>{{$t('tablefile.remaindays')}} :</v-list-item-title>
            <v-list-item-subtitle v-if="$t('default') === 'th'">{{filedata.remain_days <= 1 ? "1 วัน" : filedata.remain_days_th}}</v-list-item-subtitle>
            <v-list-item-subtitle v-else>{{filedata.remain_days <= 1 ? "1 day" : filedata.remain_days_eng}}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-content>         
            <v-list-item-title>{{$t('keydetail.permission')}} :</v-list-item-title>
            <v-list-item-subtitle>{{ permission(filedata.permission_account_v2) }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        </v-list>
           
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn @click="$emit('closedialog')" color="red" dark>{{$t('keydetail.closeDialog')}}</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>  
  </template>
  
  <script>
  import { mapState, mapGetters } from "vuex";
  import "sweetalert2/src/sweetalert2.scss";
  export default {
      props: ["show","filedata"],
      data: function() {
      return{
      }
      },
      methods:{
        calculatesize(parameter) {
        if (parameter === undefined) {
          return " ";
        }
        // console.log("parameter",parameter);
        let size;
        if (parameter >= 1099511992567 && parameter <= 1125899906842624) {
          size = (parameter / 1099511992567).toFixed(2) + " TB";
        } else if (parameter >= 1073741824 && parameter < 1099511992567) {
          size = (parameter / 1073741824).toFixed(2) + " GB";
        } else if (parameter >= 1048576 && parameter < 1073741824) {
          size = (parameter / 1048576).toFixed(2) + " MB";
        } else if (parameter >= 1024 && parameter < 1048576) {
          size = (parameter / 1024).toFixed(2) + " KB";
        } else if (parameter === "-") {
          size = parameter;
        } else {
          size = parameter + " B";
        }
  
        return size;
      },
      calculatedate(parameter) {
        let date = [];
        let month, finalDate;
        if (parameter === "00000000000000") {
          return "-";
        } else if (parameter) {
          date.push(parameter[0] + parameter[1] + parameter[2] + parameter[3]);
          date.push(parameter[4] + parameter[5]);
          date.push(parameter[6] + parameter[7]);
          date.push(parameter[8] + parameter[9]);
          date.push(parameter[10] + parameter[11]);
          date.push(parameter[12] + parameter[13]);
          var months = [
            this.$t("keydetail.month.jan"),
            this.$t("keydetail.month.feb"),
            this.$t("keydetail.month.mar"),
            this.$t("keydetail.month.apr"),
            this.$t("keydetail.month.may"),
            this.$t("keydetail.month.jun"),
            this.$t("keydetail.month.jul"),
            this.$t("keydetail.month.aug"),
            this.$t("keydetail.month.sep"),
            this.$t("keydetail.month.oct"),
            this.$t("keydetail.month.nov"),
            this.$t("keydetail.month.dec"),
          ];
          month = months[date[1] - 1];
          let datebuff =
            date[3] + ":" + date[4] + " " + date[2] + " " + month + " " + date[0];
          finalDate = datebuff;
        } else {
          finalDate = "-";
        }
  
        return finalDate;
      },
      permission(parameter) {
        console.log("parameter", parameter);
        // return parameter
        if (parameter === "01") {
          return this.$t("keydetail.viewonly");
        } else if (parameter === "02" || parameter === "03") {
          return this.$t("keydetail.downloadonly");
        } else if (
          parameter === "04" ||
          parameter === "05" ||
          parameter === "06"
        ) {
          return this.$t("keydetail.edit_download");
        }
      },
      formatdatetime(_datetime) {
        if(_datetime === "" || _datetime === null || _datetime === undefined){
          return "-";
        }else{ 
          let dateyear =
            _datetime.split("")[0] +
            _datetime.split("")[1] +
            _datetime.split("")[2] +
            _datetime.split("")[3];
          let datemonth = _datetime.split("")[4] + _datetime.split("")[5];
          let dateday = _datetime.split("")[6] + _datetime.split("")[7];
          let hour = _datetime.split("")[8] + _datetime.split("")[9];
          let minute = _datetime.split("")[10] + _datetime.split("")[11];
          let second = _datetime.split("")[12] + _datetime.split("")[13];
  
          return (
            dateday +
            "/" +
            datemonth +
            "/" +
            dateyear +
            " " +
            hour +
            ":" +
            minute +
            ":" +
            second
          );
        }
      },
          setformatdate(_date) {
        let data =
          _date.substring(8, 6) +
          "/" +
          _date.substring(6, 4) +
          "/" +
          _date.substring(0, 4);
        return data || "-";
      },
     seticon(parameter) {
        // console.log(parameter);
        // let parameter_ = parameter !== "" || parameter !== undefined || parameter !== null ? parameter.toLowerCase() : parameter
        let dataicon;
        if (parameter === "xlsx" || parameter === "xls") {
          dataicon = ["mdi-file-excel-outline", "green"];
        } else if (parameter === "pptx" || parameter === "ppt") {
          dataicon = ["mdi-file-powerpoint-outline", "orange"];
        } else if (parameter === "pdf") {
          dataicon = ["mdi-file-pdf-outline", "red"];
        } else if (parameter === "xml") {
          dataicon = ["mdi-file-code-outline", "orange"];
        } else if (parameter === "txt" || parameter === "txt") {
          dataicon = ["mdi-note-text-outline", "blue-grey"];
        } else if (parameter === "folder" || parameter === "folder") {
          dataicon = ["mdi-folder", "#FFC107"];
        } else if (
          parameter === "jpeg" ||
          parameter === "jpg" ||
          parameter === "png"
        ) {
          dataicon = ["mdi-file-image-outline", "blue-grey"];
        } else if(parameter === "avi" ||
      parameter === "mpeg" ||
      parameter === "mov" ||
      parameter === "mp4"||
      parameter === "mkv" ||
      parameter === "wmv" ||
      parameter === "3gp" ||
      parameter === "flv"){
        dataicon = ["mdi-file-video-outline", "purple"];
      }else if(parameter === "shortcut"){
        dataicon=["mdi-file-link-outline","pink"]
      }
        else {
          dataicon = ["mdi-file-question-outline", "black"];
        }
  
        return dataicon;
      },
      },
      computed:{
          // shower :{
          //     get() {
          //         if(this.show === true){
          //         }
          //         return this.show
          //     }
          // }
      ...mapState(["username", "authorize", "account_active", "color"]),
      ...mapState({ processloader: "loading" }),
      ...mapGetters([
        "dataUsername",
        "dataAuthorize",
        "dataAccountActive",
        "dataAccesstoken",
        "dataBusinessProfile",
        "dataCitizenProfile",
        "dataDepartmentAccessId",
        "dataAccountId",
      ]),
      shower :{
              get() {
                  if(this.show === true){
                  }
                  return this.show
              }
      },
      colorProgress() {
        return "color:" + this.color.theme + ";";
      },
      resolutionScreen() {
        switch (this.$vuetify.breakpoint.name) {
          case "xs":
            return 220;
          case "sm":
            return 400;
          case "md":
            return 500;
          case "lg":
            return 600;
          case "xl":
            return 800;
        }
      },
      // style อักษร header ของ mobile
      headerPage() {
        return (
          "color:" +
          this.color.theme +
          ";" +
          "font-size: 16px; font-weight: 600; font-weight: bold; letter-spacing: 0px;"
        );
      },
      // style อักษรใน dialog ของ mobile
      titleText() {
        return "color:black; font-size: 14px; line-height: 28px;";
      },
      // ความกว้างของ dialog ของ ipad และ mobile
      maxWidthOnMobile() {
        if (this.resolutionScreen >= 400) {
          return 600;
        } else {
          return 346;
        }
      },
      }
  }
  </script>